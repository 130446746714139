<template>
  <v-container fluid>
    <h3 class="mb-1">Yleiset</h3>
    <v-row dense>
      <!-- RentalContracts -->
      <v-col cols="12" sm="6" md="4" lg="3" xl="2">
        <basic-card
          title="Vuokrasopimukset"
          subtitle="Voimassa olevat vuokrasopimukset"
          icon="file-document-edit-outline"
          :amount="`${contractCount} kpl`"
          color="blue"
        ></basic-card>
      </v-col>
      <!-- Vuokrakohteet -->
      <v-col cols="12" sm="6" md="4" lg="3" xl="2">
        <basic-card
          title="Kohteet"
          subtitle="Vuokrattavat kohteet yhteensä"
          icon="home-outline"
          :amount="`${apartmentCount} kpl`"
          color="yellow"
        ></basic-card>
      </v-col>
      <!-- Tenants -->
      <v-col cols="12" sm="6" md="4" lg="3" xl="2">
        <basic-card
          title="Vuokralaiset"
          subtitle="Vuokralaisia yhteensä"
          icon="account-check"
          :amount="`${tenantCount} kpl`"
          color="success"
        ></basic-card>
      </v-col>
      <!-- KeyHolders -->
      <v-col cols="12" sm="6" md="4" lg="3" xl="2">
        <basic-card
          title="Avaimenhaltijat"
          subtitle="Avaimenhaltijoita yhteensä"
          icon="account-key"
          :amount="`${keyHolderCount} kpl`"
          color="success"
        ></basic-card>
      </v-col>
      <!-- Partners -->
      <v-col cols="12" sm="6" md="4" lg="3" xl="2">
        <basic-card
          title="Toimeksiantajat"
          subtitle="Toimeksiantajia yhteensä"
          icon="account-group"
          :amount="`${partnerCount} kpl`"
          color="blue"
        ></basic-card>
      </v-col>
      <!-- Occupancy rate -->
      <v-col cols="12" sm="6" md="4" lg="3" xl="2">
        <basic-card
          title="Käyttöaste"
          subtitle="Toiminnallinen käyttöaste 2 kk - 12 kk"
          icon="percent-outline"
          :amount="`${stats.apartment.occupancyRates.months2.toFixed(
            1
          )} - ${stats.apartment.occupancyRates.months12.toFixed(1)} %`"
          color="success"
        ></basic-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import mixins from "../../mixins/mixins";
import axiosMethods from "../../mixins/axios";
import BasicCard from "../../components/Statistics/BasicCard";
import { mapState } from "vuex";

export default {
  components: {
    BasicCard,
  },

  mixins: [mixins],

  data() {
    return {
      tenantCount: 0,
      keyHolderCount: 0,
      contractCount: 0,
      apartmentCount: 0,
      partnerCount: 0,
    };
  },

  computed: {
    ...mapState("statistics", ["stats"]),
  },

  async created() {
    this.$store.state.statistics.basicStatisticIsLoading = true;

    try {
      const tenantData = await axiosMethods.post("/api/v1/statistics/tenantData");
      const contractData = await axiosMethods.post("/api/v1/statistics/contractData");
      const apartmentData = await axiosMethods.post("/api/v1/statistics/apartmentData");
      const partnerData = await axiosMethods.post("/api/v1/statistics/partnerData");

      const tenants = tenantData.data.tenantData[0];
      const contracts = contractData.data.contractData[0];
      const apartments = apartmentData.data.apartmentData[0];
      const partners = partnerData.data.partnerData[0];

      this.tenantCount = tenants.tenantCount.length > 0 ? tenants.tenantCount[0].count : 0;
      this.keyHolderCount = tenants.keyHolderCount.length > 0 ? tenants.keyHolderCount[0].count : 0;
      this.contractCount = contracts.count.length > 0 ? contracts.count[0].count : 0;
      this.apartmentCount = apartments.count.length > 0 ? apartments.count[0].count : 0;
      this.partnerCount = partners.count.length > 0 ? partners.count[0].count : 0;

      this.$store.state.statistics.basicStatisticIsLoading = false;
    } catch (err) {
      this.showPopup("Tilastojen haku epäonnistui", "error");
    }
  },
};
</script>

<style scoped></style>
