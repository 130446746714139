<template>
  <v-container fluid>
    <!-- Talous -->
    <h3 class="mb-1 mt-1">Kuluvan kuukauden laskutus</h3>

    <v-row dense class="mb-1">
      <!-- Bills -->
      <v-col cols="12" sm="6" md="6" lg="6" xl="4">
        <basic-card
          title="Saatavat"
          subtitle="Kuluvan kk saatavat"
          icon="file-document-outline"
          :amount="`${formatCurrency(currentMonthTotalAmount)}`"
          color="primary"
        ></basic-card>
      </v-col>
      <!-- Paid -->
      <v-col cols="12" sm="6" md="6" lg="6" xl="4">
        <basic-card
          title="Suoritukset"
          subtitle="Kuluvan kk suoritukset"
          icon="currency-eur"
          :amount="`${formatCurrency(currentMonthTotalAmount - currentMonthOpenAmount)}`"
          color="success"
        ></basic-card>
      </v-col>

      <!-- waiting -->
      <v-col cols="12" sm="6" md="6" lg="6" xl="4">
        <basic-card
          title="Avoinna"
          subtitle="Kuluvan kk avoimet saatavat"
          icon="alert-circle"
          :amount="`${formatCurrency(currentMonthOpenAmount)}`"
          color="warning"
        ></basic-card>
      </v-col>
    </v-row>

    <h3 class="mb-1 mt-1">Kokonaislaskutus</h3>

    <v-row dense>
      <!-- waiting -->
      <v-col cols="12" sm="6" md="6" lg="6" xl="4">
        <basic-card
          title="Avoinna"
          subtitle="Kaikki avoimet saatavat"
          icon="alert-circle"
          :amount="`${formatCurrency(totalOpenAmount)}`"
          color="warning"
        ></basic-card>
      </v-col>
      <!-- overdue -->
      <v-col cols="12" sm="6" md="6" lg="6" xl="4">
        <basic-card
          title="Erääntyneet"
          subtitle="Kaikki erääntyneet saatavat"
          icon="clock-alert-outline"
          :amount="`${formatCurrency(totalOverdueAmount)}`"
          color="error"
        ></basic-card>
      </v-col>
    </v-row>

    <v-row dense>
      <!-- rent calculations -->
      <v-col cols="12">
        <h3 class="mb-1 mt-1">Tavoitteet</h3>

        <v-card min-height="183px">
          <v-list-item three-line>
            <v-list-item-content>
              <p class="overline">Markkinavuokrat yhteensä</p>
              <strong class="headline">{{
                formatCurrency(stats.apartment.totalEstimatedRent)
              }}</strong>

              <p class="mt-1 overline">Laskutettavat vuokrat yhteensä</p>
              <strong class="headline">{{ formatCurrency(stats.invoice.rentIncomes) }}</strong>

              <p class="mt-1 overline">Markkinavuokrasta saavutettu</p>
              <v-progress-linear
                :value="
                  stats.apartment.totalEstimatedRent
                    ? (stats.invoice.rentIncomes / stats.apartment.totalEstimatedRent) * 100
                    : 0
                "
                color="primary"
                height="25"
              >
                <template v-slot:default="{ value }">
                  <strong>{{ Math.round(value) }}%</strong>
                </template>
              </v-progress-linear>
            </v-list-item-content>

            <v-list-item-avatar size="15">
              <v-icon small>mdi-scale-balance</v-icon>
            </v-list-item-avatar>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense class="mt-1 mb-1">
      <v-col cols="12" sm="6" md="6" lg="6" xl="4">
        <h3 class="mb-1">Maksukäyttäytyminen</h3>
        <basic-card
          title="Maksuviive"
          subtitle="Viim. 365 päivän keskimääräinen maksuviive"
          icon="clock-outline"
          :amount="
            stats.payments.yearAvgPaymentDelay
              ? `${Math.round(stats.payments.yearAvgPaymentDelay)} päivää`
              : 'Ei laskettavissa'
          "
          :updatedAt="`${formatDateAndTime(stats.payments.updatedAt)}`"
          :color="
            Math.round(stats.payments.yearAvgPaymentDelay) <= 1
              ? 'success'
              : Math.round(stats.payments.yearAvgPaymentDelay) > 1 &&
                Math.round(stats.payments.yearAvgPaymentDelay) <= 7
              ? 'warning'
              : 'error'
          "
        ></basic-card>
      </v-col>
      <v-col cols="12" sm="6" md="6" lg="6" xl="4">
        <h3 class="mb-1">Kassavirta</h3>
        <basic-card
          title="Kassavirta"
          subtitle="Kuluvan kuun kassavirta ennen veroja"
          icon="cash-multiple"
          :color="calcCashFlow() < 0 ? 'error' : 'success'"
          :amount="`${formatCurrency(calcCashFlow())}`"
          tooltip="Kuluvan kuukauden saatavat - hoitovastikkeet - rahoitusvastikkeet - vesimaksut - lainanlyhennykset - muut kulut. Mikäli lainoja ei ole vielä lyhennetty tässä kuussa, ne eivät näy laskelmassa."
          :updatedAt="`${formatDateAndTime(stats.invoice.updatedAt)}`"
        ></basic-card>
      </v-col>

      <v-col cols="12" sm="6" md="6" lg="6" xl="4">
        <h3 class="mb-1">Varallisuus</h3>
        <basic-card
          title="Varallisuuden kasvu"
          :subtitle="`Ennen veroja ${
            globalValues.months[new Date(stats.invoice.updatedAt).getMonth()]
          }ssa ${new Date(stats.invoice.updatedAt).getFullYear()}`"
          icon="currency-eur"
          :color="calcGrowtOfWealth() < 0 ? 'error' : 'success'"
          :amount="`${formatCurrency(calcGrowtOfWealth())}`"
          tooltip="Kuluvan kuun kassavirta + kuluvan kuun lainojen pääomien lyhennykset. Mikäli lainoja ei ole vielä lyhennetty tässä kuussa, ne eivät näy laskelmassa."
          :updatedAt="`${formatDateAndTime(stats.invoice.updatedAt)}`"
        ></basic-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import mixins from "../../mixins/mixins";
import axiosMethods from "../../mixins/axios";
import BasicCard from "../../components/Statistics/BasicCard";
import { mapState } from "vuex";
import globalValues from "../../configs/globalValues";

export default {
  components: {
    BasicCard,
  },
  mixins: [mixins],
  data() {
    return {
      currentMonthTotalAmount: 0,
      currentMonthOpenAmount: 0,
      totalOpenAmount: 0,
      totalOverdueAmount: 0,
      globalValues,
    };
  },

  async created() {
    try {
      this.$store.state.statistics.invoiceStatisticIsLoading = true;

      const res = await axiosMethods.post("/api/v1/statistics/invoiceData");

      const monthlyData = res.data.monthlyInvoiceData[0];

      // Monthly
      this.currentMonthTotalAmount =
        monthlyData.currentMonthTotalAmount.length > 0
          ? monthlyData.currentMonthTotalAmount[0].total
          : 0;
      this.currentMonthOpenAmount =
        monthlyData.currentMonthOpenAmount.length > 0
          ? monthlyData.currentMonthOpenAmount[0].total
          : 0;

      // All time
      const allTimeData = res.data.allTimeInvoiceData ? res.data.allTimeInvoiceData[0] : null;
      const totals = allTimeData && allTimeData.totals.length > 0 ? allTimeData.totals[0] : null;

      this.totalOpenAmount = totals && totals.totalOpenAllTime ? totals.totalOpenAllTime : 0;

      this.totalOverdueAmount =
        totals && totals.totalOverdueAllTime ? totals.totalOverdueAllTime : 0;

      this.$store.state.statistics.invoiceStatisticIsLoading = false;
    } catch (err) {
      this.showPopup("Tilastojen haku epäonnistui", "error");
    }
  },

  computed: {
    ...mapState("statistics", ["stats"]),
  },

  methods: {
    calcCashFlow() {
      const totalIncomes = this.stats.invoice.cashFlowThisMonth;
      const maintenanceTotal = this.stats.apartment.maintenanceChargesThisMonth;
      const waterTotal = this.stats.apartment.waterChargesThisMonth;
      const apartmentLoanPayments = this.stats.apartment.loanPaymentThisMonth;
      const loansTotal = this.stats.loans.totalAmountThisMonth;
      const receiptsTotal = this.stats.receipt.receiptsThisMonth;

      return (
        totalIncomes -
        maintenanceTotal -
        waterTotal -
        apartmentLoanPayments -
        loansTotal -
        receiptsTotal
      );
    },

    calcGrowtOfWealth() {
      const cashFlow = this.calcCashFlow();
      const equityThisMonth = this.stats.loans.equityThisMonth;

      return cashFlow + equityThisMonth;
    },
  },
};
</script>

<style scoped></style>
